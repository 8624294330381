const DealFinancialStatus = {
  to_upload: {
    value: 'to_upload',
    color: 'warning',
  },
  customer_not_available: {
    value: 'customer_not_available',
    color: 'danger'
  },
  waiting_documents: {
    value: 'waiting_documents',
    color: 'warning',
  },
  waiting_outcome: {
    value: 'waiting_outcome',
    color: 'info',
  },
  approved: {
    value: 'approved',
    color: 'success',
  },
  rejected:{
    value: 'rejected',
    color: 'danger'
  },

  settled: {
    value: 'settled',
    color: 'default',
  },
}

export default DealFinancialStatus
