<template>
  <div class="row">
    <div class="col-12">
      <post-selling-financing-datatable/>
    </div>
  </div>
</template>

<script>
import DealsExpiredDatatable from "@/pages/Deals/datatables/DealsExpiredDatatable.vue";
import PostSellingFinancingDatatable from "@/pages/PostSelling/datatables/PostSellingFinancingDatatable.vue";
export default {
  name: "IndexFinancingPage.vue",
  components: {PostSellingFinancingDatatable, DealsExpiredDatatable}
}
</script>

<style scoped>

</style>
