const DealPaymentTypes = {
  credit_card: {
    value: 'credit_card',
  },
  debit_card: {
    value: 'debit_card'
  },
  cash: {
    value: 'cash',
  },
  financing_interest_free: {
    value: 'financing_interest_free',
  },
  financing_interest: {
    value: 'financing_interest',
  },
  transfer: {
    value: 'transfer',
  },
  rid_two: {
    value: 'rid_two',
  },
  rid_three: {
    value: 'rid_three',
  },
  paypal: {
    value: 'paypal',
  },
  soisy: {
    value: 'soisy',
  },
  scalapay: {
    value: 'scalapay',
  },
  sequra: {
    value: 'sequra',
  },
}

export default DealPaymentTypes
