<template>
  <octo-table
    show-filters
    enable-export
    :action="endpoints.DATATABLES.financings"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >

    <template v-slot:registry="data">
      <div class="text-truncate text-uppercase">
        {{ data.row.surname }} {{ data.row.name }}
      </div>
    </template>

    <template v-slot:status="data">
      <badge  v-if="data.row.status" :style="{backgroundColor: schedulerService.getDealStatusColor(data.row.status)}" class="text-uppercase">
        {{ $t('deal.status.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:accounting_status="data">
      <badge :type="dealAccountingStatuses[data.row.accounting_status].color" class="text-uppercase">
        {{ $t('deal.accounting_statuses.' + data.row.accounting_status) }}
      </badge>
    </template>

    <template v-slot:financing_status="data">
      <badge
          v-if="data.row.financing_status"
          :type="dealFinancialStatuses[data.row.financing_status].color" class="text-uppercase">
        {{ $t('deal.financing_statuses.' + data.row.financing_status) }}
      </badge>
    </template>

    <template v-slot:payment_type="data">
      <div class="small text-uppercase text-truncate" v-if="data.row.payment_type">
        {{ $t('deal.payment_types.' + data.row.payment_type ) }}
      </div>
    </template>

    <template v-slot:date_out="data">
      <div class="small">{{ data.row.date_out | date }}</div>
    </template>

    <template v-slot:amount="data">
      {{ data.row.amount | euro}}
    </template>

    <template v-slot:sales="data">
      <div class="small">
        {{ data.row.sale_firstname }} {{ data.row.sale_lastname }}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button class="mx-1" link size="sm" icon
                     @click="$router.push({name: 'post_selling.show', params: {id: data.row.id}})">
          <octo-icon icon="right-arrow" />
        </base-button>
      </div>
    </template>

    <template slot="custom-filters">

      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.status')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.statuses"
          >
            <el-option
                v-for="status in statuses"
                class="select-default text-uppercase"
                :value="status"
                :label="$t('deal.status.' + status)"
                :key="status"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.accounting_status')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.accountingStatuses"
          >
            <el-option
                v-for="status in dealAccountingStatuses"
                class="select-default text-uppercase"
                :value="status.value"
                :label="$t('deal.accounting_statuses.' + status.value)"
                :key="status.value"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.financing_status')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.financingStatuses"
          >
            <el-option
                v-for="status in dealFinancialStatuses"
                class="select-default text-uppercase"
                :value="status.value"
                :label="$t('deal.financing_statuses.' + status.value)"
                :key="status.value"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.payment_type')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.paymentTypes"
          >
            <el-option
                v-for="(option, index) in paymentOptions"
                v-bind:key="`payment-type-${index}`"
                class="select-default text-uppercase"
                :value="option"
                :label="$t('deal.payment_types.' + option)"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('deal.date_end_contract')">
          <el-date-picker
              v-model="filters.dateOutRange"
              type="daterange"
              align="right"
              unlink-panels
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('fields.date_start')"
              :end-placeholder="$t('fields.date_end')"
              :picker-options="pickerOptions"
          />
        </base-input>
      </div>
    </template>

  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable.vue";
import OctoIcon from "@/components/octo-icon/OctoIcon.vue";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge.vue";
import {Select, Option, DatePicker} from "element-ui";
import {mapGetters} from "vuex";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import LabelThemeComponent from "@/components/LabelThemeComponent.vue";
import ListGroupItemComponent from "@/components/ListGroupItemComponent.vue";
import {schedulerService} from "@/util/scheduler-service";
import DealAccountingStatus from "../../Deals/resources/dealAccountingStatus";
import DealFinancialStatus from "@/pages/Deals/resources/dealFinancialStatus";
import DealPaymentTypes from "@/pages/Deals/resources/dealPaymentTypes";

export default {
  name: "PostSellingFinancingDatatable",
  components: {
    ListGroupItemComponent,
    LabelThemeComponent,
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      endpoints: endpoints,
      name: 'FinancingsDatatable',
      filters: {},
      paymentOptions: [
          'financing_interest_free',
          'financing_interest',
          'soisy',
        'sequra'
      ],
      pickerOptions:  {firstDayOfWeek: 1},
      statuses: [
        'closed',
        'promoted',
      ],
      fields: [
        {prop: 'registry', label: 'name', slot: true, width: 250, fixed: true},
        {prop: 'date_out', label: 'date', slot: true, align: 'center', width: 150},
        {prop: 'status', label: 'deal_status', slot: true, align: 'center', minWidth: 180},
        {prop: 'accounting_status', label: 'accounting_status', slot: true, align: 'center', width: 150},
        {prop: 'financing_status', label: 'financing_status', slot: true, align: 'center', width: 180},
        {prop: 'amount', label: 'amount', slot: true, align: 'center', width: 150},
        {prop: 'payment_type', label: 'payment_type', slot: true, align: 'center', width: 200},
        {prop: 'sales', label: 'sales', slot: true, width: 200},
        {prop: 'actions', label: 'actions', width: 80, slot: true, fixed: 'right'},
      ],
      schedulerService: schedulerService,
      dealStatuses: DealStatuses,
      dealAccountingStatuses: DealAccountingStatus,
      dealFinancialStatuses: DealFinancialStatus,
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      sales: 'common/sales',
      operators: 'common/operators',
      getFilters: 'common/datatableFilters',
      courses: 'common/allCourses'
    }),

  },
  beforeMount() {
    this.filters = this.getFilters(this.name);

  },
  methods: {

  },
}
</script>

<style scoped>

</style>
